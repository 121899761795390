html,
body,
#root,
.App {
  height: 100%;
}

html {
  font-size: 16px;

  @media only screen and (max-height: 992px) {
    font-size: 13px;
  }
}

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-underline-offset: 0.2rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
